@import 'apps/common/shared/styles/mixins';

$padding-left-right-desktop-l: 264px;
$padding-left-right-desktop-m: 180px;

.profileWrapper {
  max-width: $desktop-width;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 $padding-left-right-desktop-l;

  @media screen and (max-width: 1200px) {
    padding: 0 $padding-left-right-desktop-m;
  }

  @include for-table {
    padding: 0 $table-padding;
    max-width: $table-width;
  }

  @media screen and (max-width: 450px) {
    padding: 0 $mobile-padding-m;
  }

  @include for-mobile-m {
    padding: 0 $mobile-padding-m;
  }
}

.offPadding {
  padding: 0;
}
