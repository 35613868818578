@import 'apps/common/shared/styles/mixins';

.authButton {
  @include font(15px, 500, 20px, #ffffff, -0.01em);

  box-sizing: border-box;

  width: 100%;
  height: 44px;

  padding: 11px 14px 11px 11px;

  background-color: #0077ff;

  display: flex;
  align-items: center;

  outline: none;
  border: none;
  border-radius: 8px;

  cursor: pointer;

  text-align: center;

  &:hover {
    background-color: #0058e5;
  }

  &:active {
    background-color: #004ecc;
    transform: scale(0.95);
  }

  transition: all 0.2s;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  &__content {
    margin: 0 auto;
  }
}
