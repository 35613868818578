.container {
  & :global(.vkuiModalPage__content) {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  & :global(.vkuiModalPage__content-wrap) {
    flex: 1;
  }

  & :global(.vkuiModalPage__content-in) {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
  }

  & :global(.vkuiModalPage__content-in:after) {
    flex-shrink: 0;
  }
}

.wrap {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow-y: auto;
}
