.icon {
  padding: 0 12px 12px 12px;
}

.bannerBlock {
  align-self: stretch;
}

.nextStepButton {
  width: 100%;
  padding-top: 12px;
}
