@import 'apps/common/shared/styles/mixins';

.backToProfile {
  display: flex;
  column-gap: 4px;
  align-items: center;

  margin: 15px 0 36px;
  padding: 0;
  outline: none;
  border: none;

  background-color: transparent;

  @include font(15px, 500, 20px, var(--vkui--color_text_subhead));
}
