@import 'apps/common/shared/styles/mixins';
@import 'apps/vk-music-site/src/widgets/header/style.module';

.contentLayoutProfile {
  padding-top: $header-height-size-desktop;

  @media screen and (max-width: 1200px) {
    padding-top: $header-height-size-mobile;
  }

  &__contentWrapper {
    display: flex;
    column-gap: 16px;
    padding-bottom: 15px;
  }

  &__content {
    width: 100%;
    flex-basis: initial;
    @include media-xl {
      width: 62%;
      flex-basis: 62%;
    }
  }

  &__sideBar {
    display: none;
    @include media-xl {
      width: 38%;
      flex-basis: 38%;
      display: block;
    }
  }
}
