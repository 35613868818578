$breakpoints-md: 768px;

.comboWarningBanner {
  position: relative;
  min-height: auto;
  padding: 11.5px;
  border-radius: 12px;
  display: flex;
  border: 0.5px solid transparent;

  &_withBorder {
    border-color: var(--vkui--color_separator_primary_alpha);
  }

  &_warning {
    background: var(--vkui--color_background_negative_tint);
  }

  &_attention {
    background: var(--vkui--color_background_warning);
  }
  &_success {
    background: var(--vkui--color_background_positive_tint);
  }
  &_info {
    background: var(--vkui--color_background_info_tint);
  }

  &__wrapper {
    margin-left: 12px;
    position: relative;

    @media screen and (min-width: $breakpoints-md) {
      margin-right: 8%;
    }
  }

  &__title {
    letter-spacing: -0.045em;
  }

  &__description {
    color: var(--icon_medium_alpha);
    letter-spacing: -0.006em;

    &:global(.vkuiParagraph) {
      font-size: 14px;
    }
  }

  &__footer {
    margin-top: 12px;
  }

  &__title + &__description {
    margin-top: 2px;
    color: var(--vkui--color_text_primary);
    opacity: 0.6;
  }

  &__closeIcon {
    width: 22px;
    height: 24px;
    color: var(--vkui--color_icon_secondary_alpha);
    cursor: pointer;
    position: absolute;
    right: 14px;
    top: 12px;
  }
}
