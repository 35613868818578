.benefits {
  & * + * {
    margin-top: 4px;
  }

  & li {
    list-style-type: none;
  }
}

.benefitTitle {
  font-size: 14px;
}

ul.benefits {
  margin: 0;
  padding: 0;
}

.benefitListItem {
  display: flex;
  flex-direction: row;
  align-items: center;

  & + & {
    margin-top: 5px;
  }
}

.benefitListItemIcon {
  color: var(--vkui--color_icon_accent);
  margin-right: 12px;
  position: relative;
  top: 1px;

  min-height: 16px;
  min-width: 16px;
}

.benefitListItemText {
  --vkui--font_text--font_size--regular: 14px;
}
