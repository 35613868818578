.photosWrap {
  display: flex;
  flex-direction: row;
}

.photo {
  & + & {
    margin-left: 8px;
  }
}

.result {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer {
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  padding: 0 16px 24px;
}
