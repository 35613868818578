.container {
  padding: 7px 8px;
}

.item {
  height: 39.5px;

  &:global(.vkuiSimpleCell) {
    min-height: 39.5px;
    padding-left: 12px;
  }

  & + & {
    margin-top: 2px;
  }
}
