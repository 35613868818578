.button {
  color: #fff !important;
  background-color: #f45fff !important;

  &:hover {
    background-color: #f246ff !important;
  }

  &:active {
    background-color: #ef13ff;
  }
}
