.container {
  position: relative;
  background-color: var(--vkui--color_skeleton_from);
  overflow: hidden;
}

$background-size: 400px;

.blink {
  transform: translateX(0);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: $background-size;
  background-repeat: no-repeat;
  background-position-x: -$background-size;
  animation: skeleton 1800ms cubic-bezier(0.4, 0, 0.2, 1) 0s infinite;

  background-image: linear-gradient(
    90deg,
    var(--vkui--color_skeleton_from) 0px,
    var(--vkui--color_skeleton_to) calc($background-size / 2),
    var(--vkui--color_skeleton_from) $background-size
  );
}

@keyframes skeleton {
  0% {
    background-position-x: -$background-size;
  }
  100% {
    background-position-x: calc(100% + $background-size);
  }
}
