.icon {
  &_success {
    color: var(--vkui--color_accent_green);
  }

  &_warning {
    color: var(--vkui--color_accent_orange);
  }

  &_error {
    color: var(--vkui--color_accent_red);
  }
}
