.table {
  padding-top: 0;
}

.table {
  & > * + * {
    margin-top: 10px;
  }
}

.tableItem {
  display: flex;
  justify-content: space-between;

  & + & {
    margin-top: 8px;
  }
}

.tableLabel {
  color: var(--vkui--color_text_secondary);
  flex: 1 0;
}

.tableValue {
  flex: 1 0;
}

.subscriptionContent {
  padding: 4px 0;
}
