$root-font-size: 20px;

$main-layout-size: 1200px;
$padding-layout-size: 16px;

$header-height-size: 83px;

$footer-height-size: 234px;

$mobile-width-s: 320px;
$mobile-width-m: 375px;
$mobile-width-l: 425px;
$table-width: 768px;
$desktop-width: 1440px;

$mobile-padding-s: 24px;
$mobile-padding-m: 24px;
$table-padding: 60px;
$desktop-padding: 140px;

$breakpoints-md: 768px;
$breakpoints-lg: 1024px;
$breakpoints-lg-miniapp: 911px;
$breakpoints-xl: 1280px;
$breakpoints-desktop: 1440px;
