@import 'apps/common/shared/styles/mixins';

.content {
  padding-top: 24px;
}

.rulesBlock {
  padding: 12px;

  height: 290px;

  border-radius: 12px;
  border: 1px solid var(--vkui--color_separator_primary, #d7d8d9);
  background-color: var(--vkui--color_background_secondary_alpha, #ffffff19);

  color: var(--vkui--color_text_primary, #e1e3e6ff);

  overflow-y: scroll;
  overflow-x: hidden;

  & p {
    padding-top: 20px;
    font-size: 14px;
  }

  & h3 {
    font-size: 16px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.checkboxBlock {
  display: flex;
  column-gap: 8px;
  align-items: center;

  padding: 10px 0;

  @include font(14px, 400, 20px, #ffffff, 0.1px);

  &__link {
    color: var(--vkui--color_text_link);
    text-decoration: none;
  }
}
