.desktopLayout {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.makePhotoBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 12px;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
  flex-grow: 1;

  padding: 24px 0;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 1;
  padding-bottom: 24px;
}

.title {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.105px;
}

.subtitle {
  color: var(--vkui--color_text_secondary);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  padding: 0 20px;

  text-align: center;
}

.description {
  padding: 8px 20px;
}
