@import 'apps/common/shared/styles/mixins';

.header {
  padding: 16px 0;

  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 2px;
  }

  &__skeleton_title {
    border-radius: 15px;
    height: 24px;
    width: 100px;
  }

  &__skeleton_subtitle {
    border-radius: 15px;
    height: 40px;
  }
}

.layout {
  display: flex;
  flex-direction: column;
  width: 520px;

  &__darkTheme {
    background-color: #19191a;
  }

  &__mobile {
    max-width: 520px;
    width: 100vw;
    height: calc(100vh - 118px);
  }
}

.paddingWrapper {
  padding: 0 16px;
}

.footer {
  padding-bottom: 20px;

  &__darkTheme {
    background-color: #19191a;
  }

  &__text {
    @include for-mobile-l {
      font-size: 11px;
    }
  }

  &__skeleton {
    margin-top: 20px;
    height: 30px;
    border-radius: 15px;
  }
}

.subtitle,
.footer__text {
  @include font-family(Roboto);

  color: rgba(129, 140, 153, 1);
}

.iframe {
  border: none;
  width: 100%;
  height: 560px;
  min-height: 560px;

  &__skeleton {
    height: 550px;
  }

  &__skeletonMobile {
    flex-grow: 1;
  }

  &__mobile {
    min-height: 500px;
    flex-grow: 1;
  }

  &_darkTheme {
    background-color: rgb(25, 25, 26);
  }
}
