.container {
  padding: 27px 24px 12px;
}

.title {
  margin-bottom: 21px;
}

.section {
  margin-top: 10px;
}

.checkbox {
  &:global(.vkuiCheckbox) {
    margin: 0 -16px;
  }

  &:global(.vkuiCheckbox.vkuiTappable--ios) {
    margin: 0 -12px;
  }
}

.sectionDescription {
  margin-top: 6px;
  border-radius: 12px;
  font-family: var(--font-common);
  background-color: var(--content_tint_background);
  padding: 16px;
}

.sectionTextArea {
  margin-top: 6px;
}

.footer {
  padding: 16px 24px;
}
