@mixin media_desktop {
  @media (min-width: 768px) {
    @content;
  }
}

.container {
  & :global(.vkuiModalPage__in-wrap) {
    @include media_desktop {
      max-height: calc(100vh - 20px) !important;
    }
  }
}
