.titleSkeleton {
  height: 24px;
  width: 150px;
  border-radius: 3px;
}

.subtitle {
  color: var(--dynamic_orange);
}

.description {
  color: var(--vkui--color_text_subhead);
  font-weight: 400;
  font-family: var(--font-common);
}
