@import 'apps/common/shared/styles/mixins';

.container {
  & :global(.vkuiModalPage__in-wrap) {
    @include media_desktop {
      max-height: calc(100vh - 20px) !important;
    }
  }
}

.textContainer {
  padding: 20px 24px 22px;
  color: var(--text_secondary);

  &:empty {
    display: none;
  }
}

.iframeContainer {
  padding: 0 20px 12px;
  font-size: 0;
  @media (max-width: 374px) {
    padding: 0 0 12px;
  }
}

.buttonContainer {
  padding: 16px 24px;
}

.textSkeleton {
  height: 15px;
  border-radius: 6px;

  & + & {
    margin-top: 10px;
  }
}
