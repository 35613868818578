.inlineMenu {
  display: flex;
  align-items: center;
  column-gap: 24px;
  height: 100%;
  position: relative;
  overflow-y: clip;

  @media screen and (max-width: 1279px) {
    display: none;
  }
}

.leftBlock {
  overflow-x: clip;
}

.rightBlock {
  position: relative;
  display: flex;
  align-items: center;
}

.navigation {
  --start-position: 0;
  --end-position: 150%;

  &__goInit {
    animation-name: go-init;
    animation-duration: 0.7s;
    animation-timing-function: cubic-bezier(0.56, 0.54, 0.44, 1.07);
    animation-fill-mode: forwards;
  }

  &__out {
    animation-name: go-out;
    animation-duration: 0.7s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }

  @keyframes go-init {
    0% {
      visibility: hidden;
      transform: translateX(var(--end-position));
    }

    to {
      transform: translateX(var(--start-position));
    }
  }

  @keyframes go-out {
    0% {
      opacity: 1;
      transform: translateX(var(--start-position));
    }

    40% {
      opacity: 0.2;
    }

    80% {
      opacity: 0;
    }

    to {
      transform: translateX(var(--end-position));
      opacity: 0;
      visibility: hidden;
    }
  }
}

.userBlock {
  --start-position: 0;
  --end-position: -200%;

  position: relative;

  &__goInit {
    animation-name: go-init-user-block;
    animation-duration: 0.5s;
    animation-delay: 0.15s;
    animation-timing-function: cubic-bezier(0.09, 0.31, 0, 1.2);
    animation-fill-mode: forwards;
    transform: translateY(var(--end-position));
  }

  &__out {
    animation-name: go-out-user-block;
    animation-delay: 0s;
    animation-duration: 0.3s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }

  @keyframes go-init-user-block {
    0% {
      transform: translateY(var(--end-position));
    }

    to {
      transform: translateY(var(--start-position));
    }
  }

  @keyframes go-out-user-block {
    0% {
      transform: translateY(var(--start-position));
    }

    to {
      transform: translateY(var(--end-position));
    }
  }
}

.burgerMenu {
  --start-position: 200%;
  --end-position: 0;

  transform: translateY(var(--start-position));
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;

  display: flex;
  align-items: center;

  &__goInit {
    animation-name: go-init-burger-menu;
    animation-duration: 0.3s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }

  &__out {
    animation-name: go-out-burger-menu;
    animation-duration: 0.5s;
    animation-delay: 0.15s;
    animation-timing-function: cubic-bezier(0.09, 0.31, 0, 1.2);
    animation-fill-mode: forwards;
    transform: translateY(var(--start-position));
  }

  @keyframes go-init-burger-menu {
    0% {
      transform: translateY(var(--end-position));
    }

    to {
      transform: translateY(var(--start-position));
    }
  }

  @keyframes go-out-burger-menu {
    0% {
      transform: translateY(var(--start-position));
    }

    to {
      transform: translateY(var(--end-position));
    }
  }
}

.fallback {
  width: 52px;
  height: 32px;
}
