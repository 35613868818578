// VK_Sans_Text
@font-face {
  font-weight: 100;
  font-family: 'VK Sans Text';
  font-style: normal;
  font-display: fallback;
  src: url('../fonts/VK_Sans_Text/VK_Sans_Text_Light.ttf') format('opentype');
}

@font-face {
  font-weight: 400;
  font-family: 'VK Sans Text';
  font-style: normal;
  font-display: fallback;
  src: url('../fonts/VK_Sans_Text/VK_Sans_Text_Regular.ttf') format('opentype');
}

@font-face {
  font-weight: 500;
  font-family: 'VK Sans Text';
  font-style: normal;
  font-display: fallback;
  src: url('../fonts/VK_Sans_Text/VK_Sans_Text_Medium.ttf') format('opentype');
}

@font-face {
  font-weight: 600;
  font-family: 'VK Sans Text';
  font-style: normal;
  font-display: fallback;
  src: url('../fonts/VK_Sans_Text/VK_Sans_Text_DemiBold.ttf') format('opentype');
}

@font-face {
  font-weight: 700;
  font-family: 'VK Sans Text';
  font-style: normal;
  font-display: fallback;
  src: url('../fonts/VK_Sans_Text/VK_Sans_Text_Bold.ttf') format('opentype');
}

//VK Sans Display
@font-face {
  font-family: 'VK Sans Display';
  src: local('VK Sans Display Bold Italic'), local('VKSansDisplay-BoldItalic'),
    url('../fonts/vk-sans-display/VKSansDisplay-BoldItalic.woff2') format('woff2'),
    url('../fonts/vk-sans-display/VKSansDisplay-BoldItalic.woff') format('woff'),
    url('../fonts/vk-sans-display/VKSansDisplay-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'VK Sans Display';
  src: local('VK Sans Display Bold'), local('VKSansDisplay-Bold'),
    url('../fonts/vk-sans-display/VKSansDisplay-Bold.woff2') format('woff2'),
    url('../fonts/vk-sans-display/VKSansDisplay-Bold.woff') format('woff'),
    url('../fonts/vk-sans-display/VKSansDisplay-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'VK Sans Display';
  src: local('VK Sans Display DemiBold Italic'), local('VKSansDisplay-DemiBoldItalic'),
    url('../fonts/vk-sans-display/VKSansDisplay-DemiBoldItalic.woff2') format('woff2'),
    url('../fonts/vk-sans-display/VKSansDisplay-DemiBoldItalic.woff') format('woff'),
    url('../fonts/vk-sans-display/VKSansDisplay-DemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'VK Sans Display';
  src: local('VK Sans Display Italic'), local('VKSansDisplay-Italic'),
    url('../fonts/vk-sans-display/VKSansDisplay-Italic.woff2') format('woff2'),
    url('../fonts/vk-sans-display/VKSansDisplay-Italic.woff') format('woff'),
    url('../fonts/vk-sans-display/VKSansDisplay-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'VK Sans Display';
  src: local('VK Sans Display Medium Italic'), local('VKSansDisplay-MediumItalic'),
    url('../fonts/vk-sans-display/VKSansDisplay-MediumItalic.woff2') format('woff2'),
    url('../fonts/vk-sans-display/VKSansDisplay-MediumItalic.woff') format('woff'),
    url('../fonts/vk-sans-display/VKSansDisplay-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'VK Sans Display';
  src: local('VK Sans Display Medium'), local('VKSansDisplay-Medium'),
    url('../fonts/vk-sans-display/VKSansDisplay-Medium.woff2') format('woff2'),
    url('../fonts/vk-sans-display/VKSansDisplay-Medium.woff') format('woff'),
    url('../fonts/vk-sans-display/VKSansDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'VK Sans Display';
  src: local('VK Sans Display Light'), local('VKSansDisplay-Light'),
    url('../fonts/vk-sans-display/VKSansDisplay-Light.woff2') format('woff2'),
    url('../fonts/vk-sans-display/VKSansDisplay-Light.woff') format('woff'),
    url('../fonts/vk-sans-display/VKSansDisplay-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'VK Sans Display';
  src: local('VK Sans Display Light Italic'), local('VKSansDisplay-LightItalic'),
    url('../fonts/vk-sans-display/VKSansDisplay-LightItalic.woff2') format('woff2'),
    url('../fonts/vk-sans-display/VKSansDisplay-LightItalic.woff') format('woff'),
    url('../fonts/vk-sans-display/VKSansDisplay-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'VK Sans Display';
  src: local('VK Sans Display DemiBold'), local('VKSansDisplay-DemiBold'),
    url('../fonts/vk-sans-display/VKSansDisplay-DemiBold.woff2') format('woff2'),
    url('../fonts/vk-sans-display/VKSansDisplay-DemiBold.woff') format('woff'),
    url('../fonts/vk-sans-display/VKSansDisplay-DemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'VK Sans Display';
  src: local('VK Sans Display Regular'), local('VKSansDisplay-Regular'),
    url('../fonts/vk-sans-display/VKSansDisplay-Regular.woff2') format('woff2'),
    url('../fonts/vk-sans-display/VKSansDisplay-Regular.woff') format('woff'),
    url('../fonts/vk-sans-display/VKSansDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

//VK Sans Display Condensed

@font-face {
  font-family: 'VK Sans Display Condensed';
  src: local('VK Sans Display Condensed Bold Italic'), local('VKSansDisplayCd-BdIt'),
    url('../fonts/VK-Sans-Display-Condensed/VKSansDisplayCd-BdIt.woff2') format('woff2'),
    url('../fonts/VK-Sans-Display-Condensed/VKSansDisplayCd-BdIt.woff') format('woff'),
    url('../fonts/VK-Sans-Display-Condensed/VKSansDisplayCd-BdIt.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'VK Sans Display Condensed DemiBd';
  src: local('VK Sans Display Condensed DemiBold'), local('VKSansDisplayCd-DmBd'),
    url('../fonts/VK-Sans-Display-Condensed/VKSansDisplayCd-DmBd.woff2') format('woff2'),
    url('../fonts/VK-Sans-Display-Condensed/VKSansDisplayCd-DmBd.woff') format('woff'),
    url('../fonts/VK-Sans-Display-Condensed/VKSansDisplayCd-DmBd.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'VK Sans Display Condensed';
  src: local('VK Sans Display Condensed Italic'), local('VKSansDisplayCd-It'),
    url('../fonts/VK-Sans-Display-Condensed/VKSansDisplayCd-It.woff2') format('woff2'),
    url('../fonts/VK-Sans-Display-Condensed/VKSansDisplayCd-It.woff') format('woff'),
    url('../fonts/VK-Sans-Display-Condensed/VKSansDisplayCd-It.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'VK Sans Display Condensed';
  src: local('VK Sans Display Condensed Light Italic'), local('VKSansDisplayCd-LtIt'),
    url('../fonts/VK-Sans-Display-Condensed/VKSansDisplayCd-LtIt.woff2') format('woff2'),
    url('../fonts/VK-Sans-Display-Condensed/VKSansDisplayCd-LtIt.woff') format('woff'),
    url('../fonts/VK-Sans-Display-Condensed/VKSansDisplayCd-LtIt.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'VK Sans Display Condensed';
  src: local('VK Sans Display Condensed Medium Italic'), local('VKSansDisplayCd-MdIt'),
    url('../fonts/VK-Sans-Display-Condensed/VKSansDisplayCd-MdIt.woff2') format('woff2'),
    url('../fonts/VK-Sans-Display-Condensed/VKSansDisplayCd-MdIt.woff') format('woff'),
    url('../fonts/VK-Sans-Display-Condensed/VKSansDisplayCd-MdIt.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'VK Sans Display Condensed';
  src: local('VK Sans Display Condensed Bold'), local('VKSansDisplayCd-Bd'),
    url('../fonts/VK-Sans-Display-Condensed/VKSansDisplayCd-Bd.woff2') format('woff2'),
    url('../fonts/VK-Sans-Display-Condensed/VKSansDisplayCd-Bd.woff') format('woff'),
    url('../fonts/VK-Sans-Display-Condensed/VKSansDisplayCd-Bd.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'VK Sans Display Condensed DemiBd';
  src: local('VK Sans Display Condensed DemiBold Italic'), local('VKSansDisplayCd-DmBdIt'),
    url('../fonts/VK-Sans-Display-Condensed/VKSansDisplayCd-DmBdIt.woff2') format('woff2'),
    url('../fonts/VK-Sans-Display-Condensed/VKSansDisplayCd-DmBdIt.woff') format('woff'),
    url('../fonts/VK-Sans-Display-Condensed/VKSansDisplayCd-DmBdIt.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'VK Sans Display Condensed';
  src: local('VK Sans Display Condensed Light'), local('VKSansDisplayCd-Lt'),
    url('../fonts/VK-Sans-Display-Condensed/VKSansDisplayCd-Lt.woff2') format('woff2'),
    url('../fonts/VK-Sans-Display-Condensed/VKSansDisplayCd-Lt.woff') format('woff'),
    url('../fonts/VK-Sans-Display-Condensed/VKSansDisplayCd-Lt.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'VK Sans Display Condensed';
  src: local('VK Sans Display Condensed Medium'), local('VKSansDisplayCd-Md'),
    url('../fonts/VK-Sans-Display-Condensed/VKSansDisplayCd-Md.woff2') format('woff2'),
    url('../fonts/VK-Sans-Display-Condensed/VKSansDisplayCd-Md.woff') format('woff'),
    url('../fonts/VK-Sans-Display-Condensed/VKSansDisplayCd-Md.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'VK Sans Display Condensed';
  src: local('VK Sans Display Condensed Regular'), local('VKSansDisplayCd-Rg'),
    url('../fonts/VK-Sans-Display-Condensed/VKSansDisplayCd-Rg.woff2') format('woff2'),
    url('../fonts/VK-Sans-Display-Condensed/VKSansDisplayCd-Rg.woff') format('woff'),
    url('../fonts/VK-Sans-Display-Condensed/VKSansDisplayCd-Rg.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
