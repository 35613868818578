@import 'apps/common/shared/styles/mixins';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-top: 30px;
  padding-bottom: 30px;

  @include for-table-m {
    padding-top: 24px;
    padding-bottom: 16px;
  }

  &__title {
    @include font(24px, 600, 28px, var(--vkui--color_text_primary), 1px);
  }

  &__icons {
    display: none;

    @include for-table-m {
      display: flex;
      column-gap: 25.5px;
      align-items: center;
    }
  }
}

.tabs {
  display: block;
  padding-bottom: 16px;
  @include media-xl {
    display: none;
    padding-bottom: 30px;
  }

  margin-left: -24px;
  margin-right: -24px;
  & :global(.vkuiTabsItem) {
    min-width: fit-content;
  }
  & :global(.vkuiTabs__in) {
    overflow-x: auto;
    padding-left: 24px;
    padding-right: 24px;
  }
}

.tab {
  flex: 1 0 !important;

  & :global(.vkuiTabsItem__label) {
    text-overflow: initial;
    min-width: fit-content;
  }
}
