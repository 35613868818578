@import 'apps/common/shared/styles/mixins';

.contentLayout {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 60px;

  @include for-table {
    padding: 0 $table-padding;
    max-width: $table-width;
    width: auto;
  }

  @media screen and (max-width: 450px) {
    padding: 0 $mobile-padding-m;
  }

  @include for-mobile-m {
    padding: 0 $padding-layout-size;
    max-width: $mobile-width-m;
  }

  @include for-mobile-s {
    padding: 0 $padding-layout-size;
    max-width: $mobile-width-s;
  }
}

.offPadding {
  padding: 0;

  @include for-table {
    max-width: $table-width;
  }

  @include for-mobile-m {
    max-width: $mobile-width-m;
  }

  @include for-mobile-s {
    max-width: $mobile-width-s;
  }
}
