@import 'apps/common/shared/styles/mixins';

.navigation {
  display: flex;
  align-items: center;
  column-gap: 32px;
  @media screen and (min-width: 1440px) {
    column-gap: 25px;
  }

  &__item {
    display: flex;
    column-gap: 17px;
    list-style-type: none;

    & a {
      @include font-family-vk-sans-display;
      color: var(--vkui--color_text_secondary, rgba(118, 120, 122, 1));
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.1px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;

      text-decoration: none;
      transition: 0.2s;
      position: relative;

      &:hover {
        color: #e1e3e6;
      }
    }
  }

  &__item_mobile {
    & a {
      @include font(16px, 400, 20px, var(--vkui--color_text_primary), 0.1px);
    }
  }

  &__mobile {
    flex-direction: column;
    row-gap: 32px;
    align-items: flex-start;
  }
}

.current {
  & a,
  & span {
    color: var(--vkui--color_text_primary, rgba(225, 227, 230, 1));
    font-weight: 600;

    &:after {
      content: '';
      height: 2px;
      background: #529ef4;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -4.5px;
    }
  }
}
