.container {
  height: 220px;
  display: flex;
  flex-direction: column;

  &_mobile {
    height: auto;
    flex: 1;
  }

  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.centered {
  max-width: 296px;
  padding: 10px;
  margin: 0 auto;
}

.title {
  &.title {
    font-size: 16px;
  }
}

.description {
  &.description {
    margin-top: 8px;
    font-size: 15px;
    color: var(--vkui--color_text_secondary);
  }
}
