$baseWidth: 9px;
$baseHeight: $baseWidth;
$baseX: 58.5px;
$baseY: 122.5px;
$shiftX: 16px;
$animationActualDuration: 3.4s;
$animationPauseDuration: 4s;
$animationDuration: $animationActualDuration + $animationPauseDuration;
$animationDelay: 3s;

@mixin bar($barNum, $height) {
  height: $baseHeight + $height;
  transform: translate($baseX + ($barNum - 1) * $shiftX, $baseY - calc($height / 2));
}

@function frame($start) {
  @return calc($start / $animationDuration * 100%);
}

@keyframes animate-bar-1 {
  0% {
    @include bar(1, 5);
  }

  #{frame(0.2s)} {
    @include bar(1, 5);
  }

  #{frame(0.68s)} {
    @include bar(1, 3);
  }

  #{frame(0.96s)} {
    @include bar(1, 19);
  }

  #{frame(1.24s)} {
    @include bar(1, 3);
  }

  #{frame(1.52s)} {
    @include bar(1, 19);
  }

  #{frame(1.84s)} {
    @include bar(1, 3);
  }

  #{frame(2.68s)} {
    @include bar(1, 0);
  }

  #{frame(3.4s)} {
    @include bar(1, 5);
  }

  100% {
    @include bar(1, 5);
  }
}

@keyframes animate-bar-2 {
  0% {
    @include bar(2, 5);
  }

  #{frame(0.16s)} {
    @include bar(2, 5);
  }

  #{frame(0.64s)} {
    @include bar(2, 3);
  }

  #{frame(0.8s)} {
    @include bar(2, 4);
  }

  #{frame(1.08s)} {
    @include bar(2, 19);
  }

  #{frame(1.36s)} {
    @include bar(2, 4);
  }

  #{frame(1.64s)} {
    @include bar(2, 19);
  }

  #{frame(1.96s)} {
    @include bar(2, 4);
  }

  #{frame(2.6s)} {
    @include bar(2, 0);
  }

  #{frame(3.32s)} {
    @include bar(2, 5);
  }

  100% {
    @include bar(2, 5);
  }
}

@keyframes animate-bar-3 {
  0% {
    @include bar(3, 18);
  }

  #{frame(0.12s)} {
    @include bar(3, 18);
  }

  #{frame(0.6s)} {
    @include bar(3, 3);
  }

  #{frame(0.92s)} {
    @include bar(3, 6);
  }

  #{frame(1.2s)} {
    @include bar(3, 18);
  }

  #{frame(1.48s)} {
    @include bar(3, 6);
  }

  #{frame(1.76s)} {
    @include bar(3, 18);
  }

  #{frame(2.08s)} {
    @include bar(3, 5);
  }

  #{frame(2.52s)} {
    @include bar(3, 0);
  }

  #{frame(3.24s)} {
    @include bar(3, 18);
  }

  100% {
    @include bar(3, 18);
  }
}

@keyframes animate-bar-4 {
  0% {
    @include bar(4, 41);
  }

  #{frame(0.08s)} {
    @include bar(4, 41);
  }

  #{frame(0.52s)} {
    @include bar(4, 3);
  }

  #{frame(1.04s)} {
    @include bar(4, 8);
  }

  #{frame(1.6s)} {
    @include bar(4, 8);
  }

  #{frame(1.88s)} {
    @include bar(4, 18);
  }

  #{frame(2.2s)} {
    @include bar(4, 8);
  }

  #{frame(2.44s)} {
    @include bar(4, 0);
  }

  #{frame(3.16s)} {
    @include bar(4, 41);
  }

  100% {
    @include bar(4, 41);
  }
}

@keyframes animate-bar-5 {
  0% {
    @include bar(5, 18);
  }

  #{frame(0.12s)} {
    @include bar(5, 18);
  }

  #{frame(0.6s)} {
    @include bar(5, 3);
  }

  #{frame(1.16s)} {
    @include bar(5, 4);
  }

  #{frame(1.44s)} {
    @include bar(5, 17);
  }

  #{frame(1.72s)} {
    @include bar(5, 5);
  }

  #{frame(2s)} {
    @include bar(5, 17);
  }

  #{frame(2.32s)} {
    @include bar(5, 5);
  }

  #{frame(2.52s)} {
    @include bar(5, 0);
  }

  #{frame(3.24s)} {
    @include bar(5, 18);
  }

  100% {
    @include bar(5, 18);
  }
}

.staticBar {
  display: none;
}

.bar {
  width: $baseWidth;
  fill: #fff;
  animation-duration: $animationDuration;
  animation-timing-function: ease-in-out;
  animation-delay: $animationDelay;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

.bar1 {
  @include bar(1, 5);

  animation-name: animate-bar-1;
}

.bar2 {
  @include bar(2, 5);

  animation-name: animate-bar-2;
}

.bar3 {
  @include bar(3, 18);

  animation-name: animate-bar-3;
}

.bar4 {
  @include bar(4, 41);

  animation-name: animate-bar-4;
}

.bar5 {
  @include bar(5, 18);

  animation-name: animate-bar-5;
}
