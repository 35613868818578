@import 'media';
@import 'colors';

@mixin font(
  $font-size,
  $font-weight,
  $line-height,
  $color: $general-text-white,
  $letter-spacing: 0,
  $font-family: false
) {
  @if ($font-family) {
    font-family: $font-family, -apple-system, system-ui, 'Helvetica Neue', Roboto, sans-serif;
  } @else {
    font-family: -apple-system, system-ui, 'Helvetica Neue', Roboto, sans-serif;
  }

  font-style: normal;
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  color: $color;
  letter-spacing: $letter-spacing;
}

@mixin font-family($font-family) {
  @if ($font-family) {
    font-family: $font-family, -apple-system, system-ui, 'Helvetica Neue', Roboto, sans-serif;
  } @else {
    font-family: -apple-system, system-ui, 'Helvetica Neue', Roboto, sans-serif;
  }
}

@mixin font-family-vk-sans-display {
  @include font-family('VK Sans Display');
}

@mixin placeholder($pn-color) {
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $pn-color;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: $pn-color;
  }

  :-ms-input-placeholder {
    /* IE 10+ */
    color: $pn-color;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: $pn-color;
  }

  &::placeholder {
    color: $pn-color;
  }
}

@mixin theme_dark {
  &:global(.vkui--vkBase--dark),
  &:global(.vkui--vkCom--dark),
  &:global(.vkui--vkIOS--dark),
  :global(.vkui--vkBase--dark) &,
  :global(.vkui--vkCom--dark) &,
  :global(.vkui--vkIOS--dark) & {
    @content;
  }
}

@mixin theme_light {
  &:global(.vkui--vkBase--light),
  &:global(.vkui--vkCom--light),
  &:global(.vkui--vkIOS--light),
  :global(.vkui--vkBase--light) &,
  :global(.vkui--vkCom--light) &,
  :global(.vkui--vkIOS--light) & {
    @content;
  }
}

@mixin platform_desktop {
  :global(body.vkui--vkCom--light) &,
  :global(body.vkui--vkCom--dark) & {
    @content;
  }
}

@mixin platform_mobile {
  :global(body.vkui--vkBase--light) &,
  :global(body.vkui--vkBase--dark) &,
  :global(body.vkui--vkIOS--light) &,
  :global(body.vkui--vkIOS--dark) & {
    @content;
  }
}

@mixin normalize-ul {
  margin: 0;
  margin-block: 0;
  margin-inline: 0;
  padding-inline: 0;
}

@mixin backgroundImage() {
}
