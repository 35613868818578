@import 'apps/common/shared/styles/mixins';

.applyOfferCommon {
  width: 100%;

  @include media-lg {
    width: initial;
    padding: 0;
    min-width: 330px;
    min-height: 66px;
  }
}
