$max-width: 1200px;

.menuTypeSelector__desktop {
  height: 100%;
  display: flex;
  align-items: center;

  @media screen and (max-width: $max-width) {
    display: none;
  }
}

.menuTypeSelector__mobile {
  display: none;

  @media screen and (max-width: 1279px) {
    display: block;
    margin-left: auto;
  }
}
