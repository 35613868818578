@import 'apps/common/shared/styles/mixins';

.subMenu {
  display: flex;
  flex-direction: column;
  row-gap: 22px;

  &__link {
    display: flex;
    column-gap: 17px;
    margin-bottom: 10px;

    & a {
      text-decoration: none;
      @include font(16px, 400, 20px, var(--vkui--color_text_primary), 0.1px);
    }
  }

  &__logout {
    margin-top: 10px;

    padding: 0;

    cursor: pointer;

    border: none;
    outline: none;
    background-color: transparent;
    align-items: center;
    @include font(16px, 400, 20px, var(--vkui--color_text_primary), 0.1px);
  }
}

.navigation {
  display: none;

  @media screen and (max-width: 1279px) {
    display: block;
  }

  &__scrolled {
    display: block;
  }
}

.current {
  & a,
  & span {
    @include font(16px, 400, 20px, var(--vkui--color_text_primary), 0.1px);
    position: relative;
    cursor: default;
    color: #e1e3e6;

    &:after {
      content: '';
      height: 2px;
      background: #529ef4;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -4.5px;
    }
  }
}

.submenuContainer {
  position: absolute;
  margin-top: 5px;

  width: 375px;

  transition: 0.3s;
  transform: translateY(-200%);

  opacity: 0;

  right: 0;
  padding-right: inherit;

  @media screen and (max-width: 1024px) {
    --header-height: 71px;

    width: 100%;
    right: 0;

    border-bottom: 1px solid #363738;
    padding: calc(12px + var(--header-height)) 0 16px;

    margin-top: calc(-1 * var(--header-height));

    background: #000000;
  }

  &__content {
    border: 1px solid #363738;
    border-radius: 16px;

    padding: 16px 16px 18px;

    background: #000000;

    @media screen and (max-width: 1024px) {
      border: none;
      border-radius: 0 0 10px 10px;
      padding: 0;
    }
  }

  &__opened {
    transform: translateY(0);
    opacity: 1;
  }
}

.contentLayout {
  @media screen and (min-width: 1024px) {
    padding: 0 !important;
  }
}
