@import 'apps/common/shared/styles/mixins';

.cui-title__promo-accent,
.cui-title__promo-default,
.cui-title__promo-secondary {
  @include font-family('VK Sans Display Condensed');
  font-family: 'VK Sans Display Condensed';
  font-style: italic;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.26px;

  font-size: 28px;

  @media (min-width: 375px) {
    font-size: 36px;
  }
  @media (min-width: 768px) {
    font-size: 48px;
  }
  @media (min-width: 1440px) {
    font-size: 56px;
  }
}

// title
.cui-title__promo {
  @include font-family('VK Sans Display Condensed');
  font-family: 'VK Sans Display Condensed';
  font-style: italic;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.26px;

  &_size_3 {
    font-size: 28px;

    @media (min-width: 768px) {
      font-size: 48px;
    }
  }
}

// description

.cui-spacing__promo-block-default {
  padding-top: 32px;
  padding-bottom: 32px;

  @media (min-width: 768px) {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  @media (min-width: 1280px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
