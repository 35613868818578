@import 'apps/common/shared/styles/mixins';

$accent: #71aaeb;

.accountMenu {
  background-color: #161616;
  border-radius: 10px;
}

.userBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 12px 18px 12px 16px;

  @include font(13px, 400, 16px, var(--vkui--color_text_secondary));

  &__leftSide {
    display: flex;
    align-items: center;
    column-gap: 12px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    row-gap: 2px;
  }

  &__name {
    @include font(13px, 400, 20px, var(--vkui--color_text_primary), -0.01em);
  }

  &__phone {
    @include font(13px, 400, 16px, var(--vkui--color_text_secondary));
  }

  &__vkId {
    @include font(13px, 500, 16px, $accent);
  }
}

.payBlock {
  display: flex;
  flex-direction: column;
  row-gap: 2px;

  position: relative;

  padding: 10px 16px;

  overflow: hidden;

  &__vkPay {
    @include font(12px, 400, 14px, var(--vkui--color_text_secondary));
  }

  &__open {
    @include font(13px, 500, 18px, $accent);
  }

  &__ruble {
    position: absolute;
    right: 0;
    bottom: -5px;
  }
}

:is(.payBlock, .userBlock) {
  text-decoration: none;

  &:hover {
    background-color: hsla(0, 0%, 100%, 0.04);
  }
}

.divider {
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.08);
  padding-right: 18px;
}
