.banner {
  padding: 16px 12px;

  &Content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 12px;

    border: 0.33px solid rgba(0, 0, 0, 0.08);
    border-radius: 8px;

    background-color: var(--vkui--color_background_warning);
  }

  &Header {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
  }

  &SubHeader {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }

  &Header ~ &SubHeader {
    padding-top: 6px;
  }
}
