@import 'apps/common/shared/styles/mixins';

.price {
  font-size: 14px;
  color: var(--vkui--color_text_subhead);
}

.offerItem {
  list-style-type: none;

  margin-top: 16px;

  @include media_mobile {
    margin-top: 8px;

    :global(.vkuiPanelHeader) ~ ul &:first-of-type {
      border-radius: 0 0 20px 20px;
    }
  }
}
