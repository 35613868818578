@import 'apps/common/shared/styles/mixins';

$header-height-size-desktop: 71px;
$header-height-size-mobile: 71px;

.header {
  width: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(16px);
  z-index: 10;

  border-bottom: 1px solid var(--vkui--color_separator_primary_alpha);

  &_transparent {
    background-color: transparent;
  }

  &_blur {
    backdrop-filter: blur(25px);
  }

  &__layout {
    position: relative;

    @media screen and (max-width: 1024px) {
      position: initial;
    }
  }

  &__mode_floating {
    position: fixed;
  }

  &__mode_fixed {
    position: absolute;
  }
}

.headerWrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $header-height-size-desktop;
  overflow-x: clip;

  background-color: inherit;

  box-sizing: border-box;

  @media screen and (max-width: 1200px) {
    height: $header-height-size-mobile;
  }

  z-index: 3;
}

.header__layout_wide {
  @media (min-width: 1024px) {
    max-width: 1440px;
  }
}
