.container {
  padding: 10px 11px;
}

.skeletonItem {
  height: 18px;
  width: 121px;
  max-width: 100%;
  border-radius: 4px;

  & + & {
    margin-top: 22px;
  }
}
