@import 'apps/common/shared/styles/mixins';
$breakpoints-md: 768px;

.container {
  position: relative;

  .smallScreen & {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.beforeСontainer {
  &:empty {
    display: none;
  }

  .smallScreen & {
    margin: -4px 0 20px;
  }
}

.label {
  margin-bottom: 8px;
}

.description {
  margin-top: 24px;

  .smallScreen & {
    margin-top: 11px;
  }
}

.fieldAction {
  right: 8px;
  top: 8px;
  position: absolute;

  .smallScreen & {
    position: static;
    margin-top: 12px;
  }
}
