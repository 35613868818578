@import 'sizes';

html,
body,
#root {
  height: 100%;
}

.vkui--vkBase--dark,
.vkui--vkBase--light,
.vkui--vkIOS--dark,
.vkui--vkIOS--light,
:root {
  --vkui--color_skeleton_from: #292929;
  --vkui--color_skeleton_to: #333;
}

body {
  margin: 0;
}

ul {
  margin: 0;
  margin-block: 0;
  margin-inline: 0;
  padding-inline: 0;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
