@import 'apps/common/shared/styles/mixins';

.subscription {
  @include media_mobile {
    & + & {
      margin-top: 8px;
    }

    border-radius: 20px;

    &_first {
      :global(.vkuiPanelHeader) ~ &:first-of-type {
        border-radius: 0 0 20px 20px;
      }
    }
  }
}

.description {
  padding-top: 14px;
  color: var(--vkui--color_text_subhead);
  font-weight: 400;
  font-family: var(--font-common);
}

.currentSubscription {
  font-size: 14px;
  color: var(--vkui--color_text_tertiary);
}
