@import 'apps/common/shared/styles/mixins';

.linkList {
  display: flex;
  row-gap: 16px;
  column-gap: 24px;
  flex-wrap: wrap;
  align-items: center;

  @include for-table {
    flex-direction: column;
    align-items: initial;
  }
}

.linkItem {
  list-style-type: none;
}

.link {
  @include font(16px, 400, 20px, #ffffff, -0.01em);

  text-decoration: none;
}
