@mixin image_orange($withSize: true) {
  background-image: url('./orange@1x.png');

  @supports (
    background-image:
      image-set(
        url('./orange@3x.webp') 3x,
        url('./orange@3x.png') 3x,
        url('./orange@2x.webp') 2x,
        url('./orange@2x.png') 2x,
        url('./orange@1x.webp') 1x,
        url('./orange@1x.png') 1x
      )
  ) {
    background-image: image-set(
      url('./orange@3x.webp') 3x,
      url('./orange@3x.png') 3x,
      url('./orange@2x.webp') 2x,
      url('./orange@2x.png') 2x,
      url('./orange@1x.webp') 1x,
      url('./orange@1x.png') 1x
    );
  }

  @if $withSize {
    background-size: cover;
  }
}
