.membersList {
  &__actionIcon {
    color: var(--vkui--color_icon_tertiary);
  }
}

.membersListTitle {
  &__membersCount {
    color: var(--vkui-light-text-text-subhead, #626d7a);
  }
}

.friendsSubscription {
  &__badge {
    color: var(--dynamic_neon_pink);
    //background-color: #F45FFF;
  }
}

.friendsSubscription__friend {
  margin-right: 0;

  &:last-child {
    margin-right: 0;
  }
}
