.logo {
  width: 161px;
  height: 32px;

  transform: translateY(-200%);
  transition: 0.3s;

  &__open {
    transform: translateY(0);
  }
}
