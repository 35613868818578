.iconAttention,
.iconWarning {
  flex-shrink: 0;
}

.iconAttention {
  color: var(--vkui--color_accent_orange);
}

.iconWarning {
  color: var(--vkui--color_accent_red);
}

.iconInfo {
  color: var(--vkui--color_icon_accent);
}

.iconSuccess {
  color: var(--vkui--color_icon_positive);
}
