@import 'sizes';
@import 'colors';

@mixin for-mobile-l {
  @media (max-width: 425px) {
    @content;
  }
}

/* mobile first */
/**
 width 1440px
*/
@mixin media-xxl {
  @media screen and (min-width: $breakpoints-desktop) {
    @content;
  }
}

/**
 width 1280px
*/
@mixin media-xl {
  @media screen and (min-width: $breakpoints-xl) {
    @content;
  }
}

/**
 width 1024px
*/
@mixin media-lg {
  @media screen and (min-width: $breakpoints-lg) {
    @content;
  }
}

@mixin media-lg-miniapp {
  @media screen and (min-width: $breakpoints-lg-miniapp) {
    @content;
  }
}

/**
 width 768px
*/
@mixin media-md {
  @media screen and (min-width: $breakpoints-md) {
    @content;
  }
}

@mixin media-mobile-m {
  @media screen and (min-width: $mobile-width-m) {
    @content;
  }
}

@mixin media-mobile-l {
  @media screen and (min-width: $mobile-width-l) {
    @content;
  }
}

@mixin for-mobile-s {
  @media (max-width: $mobile-width-s) {
    @content;
  }
}

@mixin for-mobile-m {
  @media (max-width: 420px) {
    @content;
  }
}

@mixin for-table {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin before-desktop {
  @media (max-width: $breakpoints-desktop) {
    @content;
  }
}

@mixin for-table-m {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin for-table-s {
  @media (max-width: $breakpoints-md) {
    @content;
  }
}

// vkBase - android, but need to check/watch

@mixin media_mobile {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin media_desktop {
  @media (min-width: 768px) {
    @content;
  }
}
