@import 'apps/common/shared/styles/mixins';
.iconSuccess {
  color: var(--vkui--color_icon_positive);
  margin-bottom: 16px;
}

.topPart {
  padding-top: 52px;
  padding-bottom: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.title {
  margin: 0 0 8px;
  @include font(23px, 600, 28px, var(--vkui--color_text_primary));
}
.description {
  margin: 0;
  @include font(16px, 400, 20px, var(--vkui--color_text_secondary));
}
.coloredTxt {
  @include font(21px, 600, 26px, var(--vkui--color_text_primary));
  background: linear-gradient(141deg, #b269ed 0%, #3b84e8 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 178px;
  margin: 0 0 auto;
}
.wrapper {
  border-radius: 12px;
  background: var(--vkui--color_background_secondary);
  color: var(--vkui--color_text_primary);
  padding: 14px 12px;
  position: relative;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  &_big {
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    position: relative;
    background: var(--vkui--color_background_secondary) url(./images/mobile.webp) 100% 100% no-repeat;
    background-size: 158px;
    height: 192px;
    box-sizing: border-box;
    & p {
      margin: 0;
      @include font(14px, 400, 18px, var(--vkui--color_text_primary));
    }
  }
}
.container {
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  flex-direction: column;
}
.icon {
  margin-right: 16px;
  color: var(--vkui--color_icon_accent);
}
.button {
  height: 44px !important;
  & :global(.vkuiButton__content) {
    display: flex;
    gap: 8px;
  }
}

.buttonWrap {
  padding: 12px;
}
