.phoneLayout {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
  flex-grow: 1;

  padding: 24px 0;
}

.makePhotoBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 12px;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 1;
  padding-bottom: 24px;
}

.title {
  font-size: 20px;
  font-weight: 510;
  line-height: 24px;
  letter-spacing: -0.09px;
  text-align: center;
}

.subtitle {
  color: var(--vkui--color_text_secondary);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: -0.05px;

  padding: 0 20px;

  text-align: center;
}

.description {
  padding: 24px 0;
}
