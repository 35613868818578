@import 'apps/common/shared/styles/mixins';

.burgerMenu {
  display: flex;
  gap: 6px;
  align-items: center;
  box-sizing: content-box;
  max-width: fit-content;
  padding: 8px 12px 8px 16px;
  cursor: pointer;
  border-radius: 12px;

  background: var(--vkui--color_background_secondary_alpha, rgba(255, 255, 255, 0.1));
}

.title {
  @include font-family-vk-sans-display;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--vkui--color_text_primary, rgba(225, 227, 230, 1));
}

.burger {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4.16px;

  padding: 3.34px 2px;

  & > * {
    width: 16px;
    height: 1.66px;
    border-radius: 100px;
    background: rgba(217, 217, 217, 1);
    transition: transform 0.35s cubic-bezier(0.52, 0.69, 0.33, 1.22), opacity 0.2s linear;
  }

  &__open div {
    &:first-child {
      transform: translateY(6px) rotate(45deg);
    }

    &:nth-child(2) {
      opacity: 0;
    }

    &:last-child {
      transform: translateY(-5.5px) rotate(-45deg);
    }
  }
}
