.content {
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: center;
}

.gradient {
  margin-left: -16px;
  margin-right: -16px;
  height: 114px;
  padding-bottom: 15px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    margin-left: -24px;
    margin-right: -24px;
  }
}

.subhead {
  margin-bottom: 8px;
  color: var(--vkui--color_text_tertiary);
}

.description {
  margin-top: 8px;
  color: var(--vkui--color_text_subhead);
}
