@import 'apps/common/shared/styles/mixins';

.friendsSubscriptionSimple {
  margin-top: 32px;
  margin-left: 0;
  margin-bottom: 12px;
  display: flex;

  @include for-table-m {
    margin-top: 24px;
  }
}

.friendsSubscriptionSimple__friend {
  margin-right: -8px;

  &:last-child {
    margin-right: 0;
  }

  &_empty {
    width: 46px;
    height: 46px;
    border: 1px dashed #d7d8d9;
    border-radius: 50%;
  }

  &:nth-child(1) {
    z-index: 4;
  }

  &:nth-child(2) {
    z-index: 3;
  }

  &:nth-child(3) {
    z-index: 2;
  }

  &:nth-child(4) {
    z-index: 1;
  }
}

.friendsSubscriptionSimple__addWrapper {
  margin-left: -15px;
  margin-right: -15px;
}
