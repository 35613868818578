.rules {
  color: var(--vkui--color_text_secondary);
  text-align: center;
  font-family: var(--font-common);
  font-size: 12px;
  margin-bottom: 12px;

  & a {
    color: var(--vkui--color_text_accent);
  }
}
