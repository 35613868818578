@import 'apps/common/shared/styles/mixins';

.subscriptionCardQ32024 {
  padding: 12px;
  border-radius: 12px;
}

.subscriptionBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__content {
    display: flex;
    align-items: center;
  }

  &[data-onclick='true'] {
    cursor: pointer;
  }
}

.chevron {
  padding-left: 42px;
}

.content {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
}

.title {
  @include font-family(Roboto);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  color: var(--vkui--color_text_primary);
}

.caption {
  @include font-family(Roboto);
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  color: var(--vkui--color_text_secondary);
}

.icon {
  height: 48px;
  width: 48px;
  border: 0.5px solid var(--vkui--color_image_border_alpha, rgba(0, 0, 0, 0.08));
  border-radius: 10px;
  margin-right: 12px;
}
