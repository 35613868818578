@import 'apps/common/shared/styles/mixins';

.button {
  display: inline-block;
  position: relative;

  outline: none;

  padding: 13.5px 24px;
  border: 0;
  margin: 0;

  border-radius: 18px;

  transition: background-color 0.15s ease-out, color 0.15s ease-out;

  cursor: pointer;

  @include font-family('VK Sans Display');
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fff;

  overflow: hidden;

  &:where(:active, :focus) {
    outline: none;
  }

  &:focus-visible {
    outline: 1.5px solid rgba(255, 255, 255, 1);
  }

  &:disabled {
    color: inherit;
    cursor: not-allowed;
    opacity: 64%;
  }

  &__description {
    padding: 6.5px 24px;
  }

  @include media-md {
    border-radius: 20px;
    padding: 19px 24px;
    font-size: 18px;

    &__description {
      padding: 9.5px 24px;
    }
  }
}

.stretched {
  display: block;
  inline-size: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.accent {
  &.primary {
    background: rgba(244, 95, 255, 1);

    &:hover:not(:disabled) {
      background: rgba(234, 46, 249, 1);
    }

    &:active:not(:disabled) {
      background: rgba(201, 33, 214, 1);
    }
  }

  &.secondary {
    background: rgba(255, 255, 255, 0.1);

    &:hover:not(:disabled) {
      background: rgba(255, 255, 255, 0.12);
    }

    &:active:not(:disabled) {
      background: rgba(255, 255, 255, 0.08);
    }
  }
}

.loading {
  &:not(.disabled) {
    cursor: progress;
  }
}

.in {
  display: flex;
  align-items: center;
  justify-content: center;
  inline-size: 100%;
  min-block-size: inherit;
  text-align: center;
  box-sizing: border-box;
}

.description {
  @include font-family('VK Sans Text');
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
  letter-spacing: -0.013em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  opacity: 70%;
  overflow: hidden;
}

.content {
  font-weight: 400;
  overflow: hidden;
}

.spinner {
  position: absolute;
  inset: 0;
  color: #fff;

  & svg {
    height: 20px;
    width: 20px;
  }
}

.spinner + .in {
  visibility: hidden;
}

.before {
  padding-inline-end: 6px;
}

.after {
  padding-inline-start: 6px;
}
