@import 'apps/common/shared/ui/backgrounds/violet';
@import 'apps/common/shared/ui/backgrounds/pink';
@import 'apps/common/shared/ui/backgrounds/purple';
@import 'apps/common/shared/ui/backgrounds/magenta';
@import 'apps/common/shared/ui/backgrounds/orange';
@import 'apps/common/shared/ui/backgrounds/blue';

.violet {
  @include image_violet();
}

.pink {
  @include image_pink();
}

.magenta {
  @include image_magenta();
}

.purple {
  @include image_purple();
}

.orange {
  @include image_orange();
}

.blue {
  @include image_blue();
}
