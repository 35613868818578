.container {
  font-size: 0;
  max-width: 783px;
}

.iframe {
  border-radius: 15px;
  height: 302px;
  width: 100%;
  border: 1px solid var(--vkui--color_field_border_alpha);
  box-sizing: border-box;
  transition: height 300ms;
}

.skeleton {
  border-radius: 15px;
  height: 302px;
}
