.text {
  &_waiting {
    color: var(--vkui--color_accent_blue);
  }
  &_active {
    color: #f45fff; // dynamic_neon_pink - looks like there is no more suck color in vk ui
  }
  &_auto_renew_cancelled {
    color: var(--vkui--color_accent_orange);
  }
  &_suspended {
    color: var(--vkui--color_accent_orange);
  }
  &_inactive {
    color: var(--vkui--color_accent_red);
  }
}
