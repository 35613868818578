.container {
  position: relative;
  width: 96px;
  height: 96px;
  min-width: 96px;
  min-height: 96px;

  border: 0.5px solid rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  background-color: inherit;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.iconButton {
  &:global(.vkuiIconButton) {
    position: absolute;
    top: 7px;
    right: 7px;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    background-color: #99a2ad;
    color: #ffffff;
  }
}
